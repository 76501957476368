import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";

let host = "https://charonv.cn8.ug.link/down"
export default class DownFile {
    constructor() {
        this.http = new HttpHandler();
    }

    getDataS(data) {
        return this.http.post(`/api/fileV/getDataS`, data, HttpConstant.FORM_DATA_REQ);
    }

    addTask(data) {
        return this.http.post(`/api/fileV/addTask`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    deleteTask(data){
        return this.http.post(`/api/fileV/delTask`, data, HttpConstant.FORM_DATA_REQ);
    }

    updateState(data){
        return this.http.post(`/api/fileV/updateState`, data, HttpConstant.FORM_DATA_REQ);
    }

    terminatedTask(data){
        return this.http.post(`/api/fileV/terminatedTask`, data, HttpConstant.FORM_DATA_REQ);
    }



    // ------------------ down 服务 ----------------
    downGetHost(data) {
        return this.http.post(`https://api.ugnas.com/api/p2p/v2/ta/nodeInfo/byAlias`, {
            "alias": "charonv"
        }, HttpConstant.APPLICATION_JSON_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }

    downGetTagData(data) {
        return this.http.post(`${host}/tag/getTagData`, data, HttpConstant.APPLICATION_JSON_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downQueryTask(data) {
        return this.http.post(`${host}/file/queryTask`, data, HttpConstant.APPLICATION_JSON_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downGetTagDataByName(data) {
        return this.http.post(`${host}/tag/getTagDataByName`, data, HttpConstant.FORM_DATA_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downTerminatedTask(data) {
        return this.http.post(`${host}/file/terminatedTask`, data, HttpConstant.FORM_DATA_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downChangeTaskState(data) {
        return this.http.post(`${host}/file/changeTaskState`, data, HttpConstant.FORM_DATA_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downDelTask(data) {
        return this.http.post(`${host}/file/delTask`, data, HttpConstant.FORM_DATA_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downAddTask(data) {
        return this.http.post(`${host}/file/addTask`, data, HttpConstant.APPLICATION_JSON_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downGetDataS(data) {
        return this.http.post(`${host}/file/getDataS`, data, HttpConstant.FORM_DATA_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
    downDelRes(data) {
        return this.http.post(`${host}/file/delTaskHard`, data, HttpConstant.FORM_DATA_REQ, {
            withCredentials: false // 动态设置withCredentials
        });
    }
}