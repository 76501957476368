<template>
  <div class="movie-card" :style="{ height: cardHeight }">
    <div class="image-container">
      <el-image
          :src="movie.poster"
          lazy
          fit="fill"
          class="image"
          :style="{ height: imageHeight }">
        <div slot="error" class="image-slot">
          <el-image
              :src="movie.preparePoster"
              lazy
              fit="fill"
              class="image" :style="{ height: imageHeight }">
            <div slot="error" class="image-slot">
              <el-image
                  :src="require('@/assets/image/2330006rVOB.jpg')"
                  lazy
                  fit="fill"
                  class="image" :style="{ height: imageHeight }">
              </el-image>
            </div>
          </el-image>
        </div>
      </el-image>
    </div>
    <p class="duration">{{ movie.duration }}</p>
    <div class="content">
      <h2>{{ movie.name }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MovieCard',
  props: {
    movie: {
      type: Object,
      required: true
    }
  },
  computed: {
    cardHeight() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 430) {
        return '280px';
      } else if (screenWidth <= 768) {
        return '300px';
      } else if (screenWidth <= 992) {
        return '330px';
      } else if (screenWidth <= 1200) {
        return '360px';
      } else if (screenWidth <= 1920) {
        return '380px';
      } else {
        return '400px';
      }
    },
    imageHeight() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        return '180px'; // 50% of 360px
      } else if (screenWidth <= 992) {
        return '210px'; // 60% of 350px
      } else if (screenWidth <= 1200) {
        return '240px';
      } else if (screenWidth <= 1920) {
        return '260px';
      } else {
        return '280px'; // 60% of 400px
      }
    }
  }
};
</script>

<style scoped>
.movie-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33% - 20px); /* 保持三列布局 */
  overflow: hidden;
  transition: height 0.3s ease;
  cursor: pointer;
}

.image-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.image {
  width: 100%;
  object-fit: cover; /* 保持图片比例 */
  transition: height 0.3s ease;
}

.content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 10px;
}

.content h2 {
  margin: 0;
  font-size: 1em;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.duration {
  font-size: 0.9em;
  color: #666;
  margin: 5px 0 0;
  text-align: center;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

@media (max-width: 9999px) {
  .movie-card {
    width: calc(100% - 20px); /* 小屏幕上两列布局 */
  }
}

@media (max-width: 1200px) {
  .movie-card {
    width: calc(100% - 20px); /* 小屏幕上两列布局 */
  }
}

@media (max-width: 996px) {
  .movie-card {
    width: calc(100% - 20px); /* 小屏幕上两列布局 */
  }
}

/* 媒体查询，适应不同屏幕尺寸 */
@media (max-width: 768px) {
  .movie-card {
    width: calc(100% - 20px); /* 小屏幕上两列布局 */
  }

  p.duration {
    font-size: 0.5em;
  }

  .content h2 {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .movie-card {
    width: 100%; /* 超小屏幕上一列布局 */
  }

  p.duration {
    font-size: 0.5em;
  }

  .content h2 {
    font-size: 0.8em;
  }
}
</style>