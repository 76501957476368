<template>
  <div id="app">
    <el-row type="flex" justify="start" style="margin: 5px 0">
      <el-col :span="8">
        <el-select v-model="query.tagIds" multiple placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagId">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="13">
        <el-input v-model="query.title" placeholder="请输入内容"></el-input>
      </el-col>
      <el-col :span="3" style="text-align: center">
        <el-button icon="el-icon-search" @click="getAllData(1)"></el-button>
      </el-col>
    </el-row>

    <el-row :gutter="12" >
      <el-col :span="spanNumber" v-for="(movie, index) in data.list" :key="host + index">
        <div @click="play(movie)">
          <movie-card
              :movie="{'poster': host + '/downFile/'+movie.filePath+'/index.jpg', 'preparePoster': movie.imgUrl, 'name': movie.fileName, 'duration': movie.creationTime}"/>
        </div>
      </el-col>
    </el-row>

    <p style="text-align: center">
      <el-pagination
          background
          @current-change="getAllData"
          :current-page="currentPage"
          :pager-count="5"
          :page-size="query.pageSize"
          layout="prev, pager, next"
          :total="data.total">
      </el-pagination>
    </p>
  </div>
</template>
<script>
import DownFile from "../../../request/expand/downFile";
import MovieCard from '../../../components/list/video-show.vue';

export default {
  name: "down_service_index",
  components: {
    MovieCard
  },
  data() {
    return {
      ask: new DownFile(),
      query: {
        "title": "",
        "tagIds": [],
        "pageNum": 1,
        "pageSize": 12,
      },
      options: [],
      spanNumber: 24,
      data: {},
      imgH: null,
      currentPage: 1,
      host: ''
    }
  },
  created() {
    this.ask.host = this.$storageHandler.getSessionStorage("nas_host");
    this.getSpan()
    if (!this.ask.host) {
      this.ask.downGetHost().then(res => {
        this.ask.host = `https://charonv.${res.data.relayDomain}/down`
        this.$storageHandler.setSessionStorage("nas_host", this.ask.host);
        this.host = this.ask.host
        this.getAllData(1)
        this.getTag()
      })
    } else {
      this.getAllData(1)
      this.getTag()
    }
    this.host = this.ask.host
  },
  mounted() {
    window.onresize = () => {
      this.getSpan()
    };
  },
  computed: {},
  methods: {
    showTitle(content) {
      this.$notify({
        title: '标题',
        message: content,
        customClass: "customClass"
      });
    },
    getTag() {
      this.ask.downGetTagData(null).then(res => {
        this.options = res
      })
    },
    getSpan() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (width < 375) {
        this.spanNumber = 24; // 小屏幕，每行显示1张图片
      } else if (width < 768) {
        this.spanNumber = 8; // 小屏幕，每行显示2张图片
      } else if (width < 992) {
        this.spanNumber = 8; // 中等屏幕，每行显示3张图片
      } else if (width < 1200) {
        this.query.pageSize = 6
        this.spanNumber = 8; // 大屏幕，每行显示4张图片
      } else if (width < 1920) {
        this.spanNumber = 6; // 大屏幕，每行显示4张图片
      } else {
        this.spanNumber = 4; // 大屏幕，每行显示4张图片
      }
    },
    getAllData(pageNum) {
      this.query.pageNum = pageNum
      this.ask.downQueryTask(this.query).then(res => {
        this.data = res
        this.currentPage = pageNum
      })
    },
    play(movie) {
      let type = movie.convertIf ? 'mp4' : 'm3u8'
      let host;
      if (type === 'm3u8') {
        host = this.ask.host + "/downFile/" + movie.filePath + "/index.m3u8"
      } else if (type === 'mp4') {
        host = this.ask.host + "/downFile/" + movie.filePath + "/" + movie.filePath + ".mp4"
      }
      const url = `/player?encipher=0&url=${escape(host)}&title=${movie.fileName}`
      window.open(url, "_blank");
    },
  }
};
</script>
<style scoped>
.el-row {
  margin-bottom: 20px;
}
</style>